<template>
    <v-container fluid class="pa-0">
        <v-img :src="require('../../assets/img/giant-building.jpg')" cover alt="#" class="max-height-440">
            <v-container id="cloudy_glass" class="overflow-hidden">
                <v-row>
                    <v-col xs="12" sm="12" md="10" xl="10" lg="10" cols="12" offset-lg="1" offset-md="1"
                           class="z-index10">
                        <v-form class="pt-5 pt-sm-10 pb-0 pb-sm-3">
                            <v-row>
                                <h2 class="main_color_text pl-3 font-weight-sm-thin">{{ $t('headers.main_filters') }}</h2>
                            </v-row>
                            <v-row>
                                <v-col class="d-flex flex-wrap flex-sm-nowrap">
                                    <v-select
                                        v-model="filter.location"
                                        id="color-icon-main"
                                        :items ="cities"
                                        :label="$t('headers.form_filter_city')"
                                        item-text="name"
                                        item-value="slug"
                                        :loading="isLoading"
                                        class="main_color_text input_text mr-0 mr-sm-3 mb-sm-n1 mb-n3 rounded-0 w-sm-100"
                                        append-icon="mdi-chevron-down"
                                        solo
                                    />

                                    <v-select
                                        v-model="filter.developer"
                                        :items = "developers"
                                        :label="$t('headers.developer')"
                                        item-text="name"
                                        item-value="slug"
                                        :loading="isLoading"
                                        class="color-icon-main input_text mr-3 mb-sm-n1 mb-n3 rounded-0 w-sm-6060"
                                        append-icon="mdi-chevron-down"
                                        solo
                                        depressed
                                    />

                                    <v-select
                                        v-model="filter.completion"
                                        :items="years"
                                        :label="$t('headers.form_filter_deadline')"
                                        class="main_color_text input_text rounded-0 w-sm-4060"
                                        append-icon="mdi-chevron-down"
                                        item-text="name"
                                        item-value="value"
                                        :loading="isLoading"
                                        solo
                                    />

                                    <div class="filter_button d-flex align-baseline sm-100-justify-mt">
                                        <v-btn
                                            class="main_color_text transparent mr-1 text-capitalize subtitle-1"
                                            type="reset"
                                            depressed
                                            @click="$store.dispatch('project/resetFilter')"
                                        >
                                            {{ $t('headers.form_filter_btn_clear') }}
                                        </v-btn>

                                        <v-btn
                                            class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                                            depressed
                                            @click="$emit('search')"
                                        >
                                            {{ $t('headers.form_filter_btn_apply') }}
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        years: [],
    }),

    computed: {
        ...mapGetters({
            isLoading: 'lists/loading',
            filter: 'project/filter',
            cities: 'lists/cities',
            developers: 'lists/developers',
        })
    },

    mounted() {
        this.setYears()
        this.$store.dispatch('lists/fetchCities')
        this.$store.dispatch('lists/fetchDevelopers')
    },

    methods: {
        setYears() {
            for (let num = 0; num <= 10; num++) {
                if (!num) {
                    this.years.push({ name: this.$t('filterForm.turned_in'), value: 'ready' })
                }

                let date = new Date().getFullYear() + num

                this.years.push({ name: date, value: date.toString() })
            }
        }
    },

    beforeDestroy() {
        this.$store.dispatch('lists/resetCities')
        this.$store.dispatch('lists/resetDevelopers')
    }
}
</script>

<style lang="scss" scoped>
.elevation-0 {
    z-index: 0 !important;
    box-shadow: 0 0 0 0 transparent;
}

#cloudy_glass {
    background: transparent !important;
    position: relative;
    z-index: 1;
    margin-top: 40px;
    margin-bottom: 15px;

    @media all and (max-width: 1240px) {
        margin: 15px 5px;
        max-width: calc(100% - 10px);
        box-sizing: border-box;
    }


    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        backdrop-filter: blur(5px);
        z-index: 2;
        background: linear-gradient(135deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, 0));
        border-radius: 0;
        background: rgba(224, 224, 224, 0.5);
    }
}

.filter-total-area {
    max-width: 205px;
}

.input_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
}

.filter_submit_btn {
    background: #FFFFFF;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.z-index10 {
    z-index: 10;
}

.color-icon-main,
#color-icon-main {
    color: var(--main_color-base) !important;
}

.v-icon,
.v-icon.notranslate.mdi.mdi-chevron-down.theme--light,
.v-input__append-inner .v-input__icon.v-input__icon--append .v-icon.notranslate.mdi.mdi-chevron-down.theme--light,
#cloudy_glass > div > div > form > div:nth-child(2) > div > div.v-input.color-icon-main.input_text.mr-1.rounded-0.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--is-booted.v-text-field--enclosed.v-select > div > div.v-input__slot > div.v-select__slot > div.v-input__append-inner > div > i,
.theme--light.v-icon {
    color: var(--main_color-base) !important;
}

.bg-img {
    min-height: 375px;
}

@media all and (max-width: 770px) {
    .sm-100-justify-mt {
        flex-basis: 100%;
        justify-content: space-between;
        margin-top: 20px;
    }

    .w-sm-100 {
        width: 100%;
    }

    .w-sm-6060 {
        width: calc(60% - 60px)
    }

    .w-sm-4060 {
        width: calc(40% - 60px)
    }

    .w-sm-605 {
        min-width: calc(60% - 7px) !important;
    }

    .w-sm-405 {
        width: calc(40% - 5px)
    }

    .font-weight-sm-thin {
        font-weight: 100;
    }
}

.max-height-440 {
    max-height: 350px;

    @media all and (max-width: 770px) {
        max-height: inherit;
    }
}
</style>
